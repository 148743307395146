body.page_404{
    background: #f3f6f7;
}
.page_404 {
    padding: 0;
    max-width: unset;
}
.page_404 .aux-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10.2rem;
    justify-content: center;
    position: absolute;
    padding-left: 5rem;
    padding-right: 5rem;
    align-items: center;

}
.page_404 .wrap-content{
    max-width: 90rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
}
.page_404 h2{
    font-family: 'Montserrat-Bold';
    text-align: center;
    color: #1197a5;
    font-size: 3.5rem;
    margin-top: 5rem;
    margin-bottom: 3.4rem;

}
.page_404 .wrap-num{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;

    .item{
        margin: 0 8%;
        text-align: center; 
        width: 40%;

        &:first-of-type,
        &:last-of-type{
            margin-left: 0;
            margin-right: 0;
            max-width: 20.6rem; 
            width: 30%;
        }

    }
}

.page_404 .wrap-num img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.page_404 .wrap-btnDefault {
    //max-width: 24.5rem;
    min-width: unset;

    .btnDefault {
        padding: 1.4rem;
        font-size: 1.5rem;
        line-height: 1.7rem;
        letter-spacing: .3rem;
        font-family: 'Montserrat-Bold';
    }
}


@media(max-width: 991px){
    .page_404 .aux-content{
        padding-top: 10.2rem;
        justify-content: center;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        flex-grow: 1;
        overflow: hidden;
    }
    .page_404 .wrap-content{
        justify-content: center;
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative;
        padding-bottom: 4rem;
        top: unset;
        left: 0;
        bottom: unset;
        transform: none;
    }
    .page_404 h2{
        font-size: 2.1rem;
        margin-top: 6.2rem;
        margin-bottom: 4.4rem;
    }
    .page_404 .wrap-num{
        flex-direction: column;

    }

    .page_404 .wrap-num .item:nth-child(1n){
        align-self: flex-start;
        text-align: center;
        margin: 0 !important;
        width: 25% !important;

    }
    .page_404 .wrap-num .item:nth-child(2n){
        align-self: center;
        margin: 0;
        margin-right: 0;
        margin-left: 0;
        text-align: center;
        width: 56% !important;
        /* min-width: 21.2rem !important; */
        margin-top: -1.5rem !important;

    }
    .page_404 .wrap-num .item:nth-child(3n){
        align-self: flex-end;
        text-align: center;
        margin: 0;
        width: 25% !important;
        /* min-width: 6.9rem; */
        margin-top: -5rem !important;
    }

    .page_404 .wrap-num img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .page_404 .wrap-btnDefault {
        max-width: unset;
    }
    .wrap-btnDefault .btnDefault {
        font-size: 1.7rem;
    }
}